<template>
  <el-main>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true" :model="form">
          <el-form-item label="时间范围">
            <el-date-picker
                v-model="form.dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="exportData">导出</el-button>
      </el-col>
    </el-row>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="department_name"
          label="部门"
      >
      </el-table-column>
      <el-table-column
          prop="platform_name"
          label="平台"
      >
      </el-table-column>
      <el-table-column
          prop="type_cn"
          label="任务类型"
      >
      </el-table-column>
      <el-table-column
          prop="job_count"
          label="需求次数">
      </el-table-column>
      <el-table-column
          prop="count"
          label="关键词/链接数量">
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  name: "Statistics",
  created() {
    let me = this;
    Promise.all([me.axios.get('/api/platform/list')
      , me.axios.get('/api/department/list')
    ])
        .then(([platforms, departments]) => {
          platforms.forEach(platform => {
            me.map.platform['id_' + platform.id] = platform.name;
          })
          departments.forEach(department => {
            me.map.department['id_' + department.id] = department.name;
          })
        })
  },
  data() {
    return {
      map: {
        department: {},
        platform: {},
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        }
      },
      form: {
        dateRange: []
      },
      rawData: []
    }
  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val => {
        val['department_name'] = me.map.department['id_' + val['department_id']];
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['type']];
        return val;
      });
    }
  },
  methods: {
    search() {
      let me = this;
      let params = {};
      if (this.form.dateRange) {
        params['start_time'] = this.formatdate(this.form.dateRange[0], 'YYYY-MM-DD');
        params['end_time'] = this.formatdate(this.form.dateRange[1], 'YYYY-MM-DD');
      }
      me.axios.post('/api/task/statistics', params).then(res => {
        me.rawData = res;
      })
    },
    exportData() {
      let me = this;
      let params = {};
      if (this.form.dateRange) {
        params['start_time'] = this.formatdate(this.form.dateRange[0], 'YYYY-MM-DD');
        params['end_time'] = this.formatdate(this.form.dateRange[1], 'YYYY-MM-DD');
      }
      let url=me.const.baseURL + '/api/task/exportStat?start_time=' + (params['start_time'] ? params['start_time'] : '') + '&end_time='
      + (params['end_time'] ? params['end_time'] : '') + '&token=' + localStorage.getItem('token');
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>

</style>